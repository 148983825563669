<template>
  <v-sheet class="pa-5 pb-10" elevation="1">
    <h1 class="display-1">Testing Order</h1>
    <v-divider class="mt-3"></v-divider>
    <div class="mt-5 d-flex justify-space-between align-center">
      <div>
        <v-select
          v-model="van"
          :items="vanItems"
          item-text="brandModel"
          item-value="id"
          return-object
          outlined
          dense
          hide-details
        >
        </v-select>
      </div>
    </div>
    <v-row class="mt-5">
      <v-col cols="12" md="6"
        ><v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              dense
              append-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              @blur="dateTmp = parseDate(date)"
              v-on="on"
              label="Date"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            v-model="dateTmp"
            @input="dateMenu = false"
            locale="fr"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          dense
          outlined
          v-mask="['##:##']"
          label="Time"
          placeholder="12:00"
          v-model="time"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-btn>Test</v-btn>
  </v-sheet>
</template>

<script>

import { mask } from "vue-the-mask";
import * as dayjs from 'dayjs'
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {

  directives: { mask },

  data() {
    return {
      vanItems: [],
      van: '',
      tripsArray: [],

      dateMenu: false,
      dateTmp: '',
      date: '17-05-2022',
      time: "10:00",
    }
  },

  watch: {
    async van(val) {
      this.tripsArray = await this.$store.dispatch("order/getOrderByStatusDateVanId", { date: this.date, status: 'Scheduled', vanId: val.id })
      let orderTime = this.getCompleteTripTime(this.tripsArray[0])


      let dateTime = `${this.formatDate(this.date)} ${this.time}`

      let unixDateTime = Number(dayjs(dateTime).format('X'))

      // console.log(unixDateTime)
      // console.log(Number(dayjs(orderTime.split(' - ')[0]).format('X')))
      // console.log(Number(dayjs(orderTime.split(' - ')[1]).format('X')))

      if(unixDateTime > Number(dayjs(orderTime.split(' - ')[0]).format('X')) && unixDateTime < Number(dayjs(orderTime.split(' - ')[1]).format('X'))) {
        console.log('Yes it does')
      } else {
        console.log("No it doesn't")
      }
    },
    dateTmp(val) {
      this.date = this.formatDate(this.dateTmp);
    },
  },

  mounted() {
    this.$store.dispatch('van/getVans').then(vanArray => {
      this.vanItems = vanArray
    })
  },

  methods: {

    getCompleteTripTime(tmpObject) {
      // Main trip
      let mainTripStartTime = dayjs.unix(tmpObject.pickUpDateTime).format('YYYY-MM-DD HH:mm')
      let mainTripEndTime = dayjs(mainTripStartTime).add(Number(tmpObject.estimatedTime.split(' ')[0]), 'm').format('YYYY-MM-DD HH:mm')

      // Garage to source time and rest
      let tpcDbStart = dayjs(mainTripStartTime).subtract(tmpObject.garageToSource.time + tmpObject.garageToSource.rest, 'm').format('YYYY-MM-DD HH:mm')
      let tpcDbEnd = dayjs(tpcDbStart).add(tmpObject.garageToSource.time, 'm').format('YYYY-MM-DD HH:mm')

      // After source rest
      let trPreVStart = tpcDbEnd
      let trPreVEnd = mainTripStartTime

      // Post trip Rest
      let trPostVStart = mainTripEndTime
      let trPostVEnd = dayjs(mainTripEndTime).add(tmpObject.destinationToGarage.rest, 'm').format('YYYY-MM-DD HH:mm')

      // Destination to garage
      let treVbStart = trPostVEnd
      let treVbEnd = dayjs(trPostVEnd).add(tmpObject.destinationToGarage.time, 'm').format('YYYY-MM-DD HH:mm')

      return `${tpcDbStart} - ${trPostVEnd}`
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  }



}
</script>

<style>

</style>